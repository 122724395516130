import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BsEyeFill } from "react-icons/bs";
import style from "./classifieds.module.css";
import ListTables from "../../components/tables/ListTables";
import { useGetClassifiedsQuery } from "../../redux/api/classifiedApi";
import ViewClassifiedPost from "./ViewClassifiedPost";

const ClassifiedPosts = ({ classifiedPost, setClassifiedPost }) => {
  const Query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  useEffect(() => {
    if (!Query.get("classified")) {
      alert("classified param is missing in the url");
    }
    if (!Query.get("id")) {
      alert("classified Id is missing in the url");
    }
  }, [Query]);

  const dataRemap = (data) => {
    return data.classifieds.map(filter);
  };

  const filter = (obj) => {
    return {
      _id: obj._id,
      userPosted: obj.user_id ? obj.user_id.name : "dummy",
      id: obj._id,
      title: obj.title,
      description: obj.description,
      classifiedType: obj.classifiedType,
    };
  };

  const viewHandler = async (list) => {
    setClassifiedPost(list);
  };

  const AddClassified = () => {
    navigate(`/rm-admin/classifieds/add-classified-post?id=${Query.get("id")}`);
  };

  return (
    <div className="root-top-margin">
      {Query.get("classified") && (
        <div className={style.addIcon}>
          <button className="btn btn-success" onClick={AddClassified}>
            Add {Query.get("classified").replace("-", "&")}
          </button>
        </div>
      )}
      {!classifiedPost && (
        <div>
          <ListTables
            search={true}
            tableTitle="Lists of classifieds"
            tableHeders={[
              "userPosted",
              "id",
              "title",
              "description",
              "classifiedType",
              "Actions",
            ]}
            paramAdditional={{ category_id: Query.get("id") }}
            useGetListHook={useGetClassifiedsQuery}
            dataRemap={dataRemap}
            actions={[{ icon: <BsEyeFill />, handler: viewHandler }]}
          />
        </div>
      )}
      {classifiedPost._id && (
        <ViewClassifiedPost
          setClassifiedPost={setClassifiedPost}
          classifiedPost={classifiedPost}
        />
      )}
    </div>
  );
};

export default ClassifiedPosts;
