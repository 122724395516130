import React, { useEffect, useState } from 'react'
import { IoIosLogOut,IoMdMenu,IoMdClose } from "react-icons/io";
import style from '../components/navbar/navbar.module.css'


const Logout = ({ navOpen,setNavOpen}) => {

    const logOuthandler = ()=>{
        localStorage.removeItem("RaselListing");
        window.location.replace("/#/rm-admin");
    }
  
  return (

        <div className={`${style.topMenu}`}>
            {!navOpen && <>
            <span>
               <IoMdMenu size={20} onClick={()=>{
                document.getElementById("sideNavmenu").style.display="block"
                setNavOpen(true)
            }
                
                }/>
            </span>
        <span onClick={logOuthandler}>
            <IoIosLogOut size={20}/>
            <span style={{marginLeft:"2px"}}>Logout</span>
        </span>
        </>}

        {navOpen && <span>
            <IoMdClose size={30} style={{position:"absolute", top:"30px", right:"10px", cursor:"pointer"}} onClick={()=>{document.getElementById("sideNavmenu").style.display="none"
         setNavOpen(false)

            }}/>
            </span>}
        </div>

  )
}

export default Logout
