import React, { useState } from 'react'
import { BsEyeFill } from 'react-icons/bs'
import ListTables from '../../components/tables/ListTables'
import { useGetStoriesQuery } from '../../redux/api/storiesApi'
import ViewStory from '../../components/story/ViewStory'

const Stories = () => {
  const [viewPost, setViewPost] = useState('')
  const [page, setPage] = useState(1)

  const dataRemap = (data) => {
    return data.stories.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      id: obj._id,
      userName: obj.user_id ? obj.user_id.name : 'Not found',
      icon: (
        <img
          src={obj.story_url}
          style={{ height: '100px', width: '100px' }}
          alt={obj._id + ' story'}
        />
      ),
      Date: new Date(obj.createdAt).toLocaleDateString('de-DE'),
      Status: obj.active,
    }
  }

  const viewPostHandler = async (story) => {
    setViewPost(story._id)
  }

  return (
    <div>
      <div className='root-top-margin'>
        {console.log(viewPost)}
        {!viewPost && (
          <ListTables
            search={true}
            tableTitle='Story List Table'
            tableHeders={[
              'Id',
              'Username',
              'Story File',
              'Date',
              'Status',
              'Action',
            ]}
            dataRemap={dataRemap}
            useGetListHook={useGetStoriesQuery}
            actions={[{ icon: <BsEyeFill />, handler: viewPostHandler }]}
            setPage={setPage}
            page={page}
            maxCountInaPage={10}
            // errordata={
            //   error &&
            //   (error.data.error ? error.data.error : 'some thing went wrong')
            // }
          />
        )}
        {viewPost && <ViewStory ID={viewPost} setViewPost={setViewPost} />}
      </div>
    </div>
  )
}

export default Stories
