import React, { useState } from 'react'
import style from './user.module.css'
import userdefualt from '../../images/user2.jpg'
import { useNavigate } from 'react-router-dom'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import {
  useGetUserByIDQuery,
  useBlockUserMutation,
  useUserStatusMutation,
} from '../../redux/api/userApi'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import Error from '../Error'
import Posts from '../../pages/posts/Posts'

const User = ({ ID, setViewUser }) => {
  const navigate = useNavigate()

  const { data, error, isLoading, isFetching, isSuccess } = useGetUserByIDQuery(
    {
      user_id: ID,
    }
  )


  const [
    blockUser,
    { isLoading: isUpdating, isSuccess: isUpdateSuccess, error: updateError },
  ] = useBlockUserMutation()

  const [
    userStatus,
    { isLoading: isUpdatingStatus, isSuccess: isUpdatestatusSuccess, error: updateStatusError },
  ] = useUserStatusMutation()

  if (error && error.status === 401) {
    removeLocalStorageAndNavigate(navigate)
  }

  if (updateError && updateError.status === 401) {
    removeLocalStorageAndNavigate(navigate)
  }

  if (updateStatusError && updateStatusError.status === 401) {
    removeLocalStorageAndNavigate(navigate)
  }

  const blockUserHandler = async (id, isBlock) => {
    if (window.confirm('Do you want to block the user ?')) {
      const data = { block_user_id: id, toBlock: isBlock }
      await blockUser(data)
    }
  }

  const userStausHandler = async (id, active) => {
      const data = { user_id: id, isActive: active }
      await userStatus(data)
    }
  
  return (
    <>
      <div className={style.userWrapper}>
        <BsFillArrowLeftCircleFill
          className='cursor-pointer'
          size={20}
          onClick={() => setViewUser(false)}
        />
        {error && (
          <Error
            error={
              error.data.error ? error.data.error : 'some thing went wrong'
            }
          />
        )}

        {updateError && (
          <Error
            error={
              updateError.data.error
                ? updateError.data.error
                : 'some thing went wrong'
            }
          />
        )}

{updateStatusError && (
          <Error
            error={
              updateStatusError.data.error
                ? updateStatusError.data.error
                : 'some thing went wrong'
            }
          />
        )}
        {(isLoading || isFetching || isUpdating || isUpdatingStatus) && (
          <div className='flex-item-center'>
            <span className='loader-spiner'></span>
          </div>
        )}

        {isSuccess && (
          <>
            <div className='flex-item-space-btw'>
              <div className={style.userNameBox}>{data.name}</div>
              <div>
                <img
                  src={data.photo}
                  alt='profile'
                  className={style.userProfileImg}
                  onError={(e) => {
                    e.target.onError = null
                    e.target.src = userdefualt
                  }}
                />
              </div>
            </div>
            <div style={{ padding: '20px' }}>
              <span className='mr-1'>Created At</span>
              <span className='mr-1'>
                {new Date(data.createdAt).toLocaleDateString()}
              </span>
              {new Date(data.createdAt).toLocaleTimeString()}
            </div>
            <div className={style.userinfoWrapper}>
              <div className={style.userInfoBox}>
                <div>Email:</div>
                <div>{data.email}</div>
              </div>
              <div className={style.userInfoBox}>
                <div>Gender:</div>
                <div>{data.gender}</div>
              </div>
              <div className={style.userInfoBox}>
                <div>Mobile:</div>
                <div>{data.phone}</div>
              </div>

              <div className={style.userInfoBox}>
                <div> User Status:</div>
                <div>
                  <select defaultValue={data.active} onChange={(e)=>userStausHandler(data._id,e.target.value)}>
                    <option value={true}>active</option>
                    <option value={false}>pending</option>
                  </select>
                </div>
              </div>
              <div className={style.userInfoBox}>
                <div>Block User:</div>
                <div>
                  <input
                    type='checkbox'
                    onChange={(e) =>
                      blockUserHandler(data._id, e.target.checked)
                    }
                    checked={data.blocked}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Posts user_id={ID} />
    </>
  )
}

export default User
