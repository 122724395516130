import React, { useState, useRef } from 'react'
import axios, { CancelToken, isCancel } from 'axios'
import { useNavigate } from 'react-router-dom'
import { BsCloudUpload } from 'react-icons/bs'
import style from './classified.module.css'
import { useGetPreSignedUrlMutation } from '../../redux/api/uploadApi'
import {
  useAddClassifiedsCategoryMutation,
  useAddSubClassifiedsCategoryMutation,
  useEditSubClassifiedCategoryMutation,
  useGetClassifiedsCategoryQuery,
} from '../../redux/api/classifiedApi'

import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
const AddSubClassifiedCategory = ({
  setAddSubClassified,
  subClassifiedCat,
  setSubClassifiedCat,
  setSubCategories,
}) => {
  const navigate = useNavigate()

  console.log('dasdsa  cat',subClassifiedCat)
  const [nameEn, setNameEn] = useState(
    subClassifiedCat._id ? subClassifiedCat.sub_category_en : ''
  )
  const [nameAr, setNameAr] = useState(
    subClassifiedCat._id ? subClassifiedCat.sub_category_ar : ''
  )
  const [imgsrc, setImagesrc] = useState(
    subClassifiedCat._id ? (subClassifiedCat.image && subClassifiedCat.image.props.src) : ''
  )
  const [imgeFile, setImgeFile] = useState('')
  const [loading, setLoading] = useState(false)
  const filePikerRef = useRef(null)

  const [categoryId, setCategoryId] = useState(
    subClassifiedCat._id ? subClassifiedCat.category_id : ''
  )

  const [getPreSignedUrl] = useGetPreSignedUrlMutation()
  const [addSubClassifiedsCategory] = useAddSubClassifiedsCategoryMutation()
  const [editSubClassifiedCategory] = useEditSubClassifiedCategoryMutation()

  const { data, error, isLoading, isFetching, isSuccess } =
  useGetClassifiedsCategoryQuery({})

  console.log(data)

if (error && error.status === 401) {
  removeLocalStorageAndNavigate(navigate)
}

  const filePickerHandeler = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImgeFile(event.target.files[0])
      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.addEventListener('load', () => {
        setImagesrc(reader.result)
      })
    } else {
      alert('no file selected')
    }
  }

  const onSaveHandler = async () => {
    if (!nameEn) {
      alert(' name EN is required')
      return
    }
    if (!nameAr) {
      alert(' name AR is required')
      return
    }

    if(!categoryId){
      alert('Category is required')
      return 
    }

    if (subClassifiedCat._id) {
      setLoading(true)

      if (!imgeFile && imgsrc) {
        editClassified(imgsrc)
      }
      if (imgeFile) {
        getPresignedUrl([imgeFile.name], imgeFile.type, imgeFile)
      }else{
        console.log('empty image')
        editClassified("")
        return
      }
    } else {
      if (!imgeFile) {
        addClassifieds("")
        return
      }
      setLoading(true)
      getPresignedUrl([imgeFile.name], imgeFile.type, imgeFile)
    }
  }

  const addClassifieds = async (fileurl) => {
    const res = await addSubClassifiedsCategory({
      sub_category_en: nameEn,
      sub_category_ar: nameAr,
      sub_category_image: fileurl,
      category_id:categoryId,
    })

    if (res.error) {
      if (res.error.status === 401) {
        removeLocalStorageAndNavigate(navigate)
      }else{
        alert(res.error.data.error)
      }
    }
    if (res.data) {
      setImagesrc('')
      setNameAr('')
      setNameEn('')
      setImgeFile('')
      setAddSubClassified(false)
      setSubClassifiedCat({ _id: '' })
    }
    setLoading(false)
  }

  const editClassified = async (fileurl) => {
    const res = await editSubClassifiedCategory({
      category_id: subClassifiedCat.category_id,
      sub_category_id: subClassifiedCat._id,
      sub_category_en: nameEn,
      sub_category_ar: nameAr,
      sub_category_image: fileurl,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
    }
    if (res.data) {
      setImagesrc('')
      setNameAr('')
      setNameEn('')
      setImgeFile('')
      setAddSubClassified(false)
      setSubClassifiedCat({ _id: '' })
    }
    setLoading(false)
  }

  const getPresignedUrl = async (fileNames, contentType, imgeFile) => {
    const res = await getPreSignedUrl({
      fileNames: fileNames,
      contentType: contentType,
      flocation: 'posts',
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
      setLoading(false)
    }
    if (res.data) {
      await uploadFile(
        res.data.preSignedUrls[0].preSignedUrlToUplaodToAWS,
        res.data.preSignedUrls[0].pathNametoStoreInDBaftersucessfullupload,
        imgeFile
      )
    }
  }

  const uploadFile = async (prsignedURL, storeTODB, imgeFile) => {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent

        let percent = Math.floor((loaded * 100) / total)
        console.log(percent)
      },
    }
    axios
      .put(prsignedURL, imgeFile, options)
      .then((res) => {
        if (subClassifiedCat._id) {
          editClassified(storeTODB)
        } else {
          addClassifieds(storeTODB)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <div>
      <div className={style.classifiedHeaderWrapper}>
        Add New Sub Classified Category
      </div>
      <div className={style.addCategoryWrapper}>
        {loading && (
          <div>
            <span className='loader-spiner'></span>
            <span className='text-primary'>...adding post</span>
          </div>
        )}
        <div className={style.imageWrapper}>
          <input
            type='file'
            ref={filePikerRef}
            accept='image/*'
            className='display-n'
            onChange={filePickerHandeler}
          />
          {imgsrc && (
            <div className='text-align-center'>
              <img src={imgsrc} alt='catgeory' className={style.catImage} />
              <div
                className={style.uploadImgButton}
                onClick={() => {
                  if (loading) {
                    alert('please wait its uploading')
                  } else {
                    filePikerRef.current.click()
                  }
                }}
              >
                Change Image <BsCloudUpload />
              </div>
            </div>
          )}
          {!imgsrc && (
            <div
              className={style.uploadImgButton}
              onClick={() => {
                if (loading) {
                  alert('please wait its uploading')
                } else {
                  filePikerRef.current.click()
                }
              }}
            >
              Add Image <BsCloudUpload />
            </div>
          )}
        </div>
        <div className={style.nameTextsWrapper}>
          <div>
          {isSuccess && data.length > 0 && (
                <select
                  onChange={(e) => setCategoryId(e.target.value)}
                  className={`mt-3 mb-3 mr-1`}
                  defaultValue={categoryId}
                  style={{width:"50%"}}
                >
                  <option value='0'>Select Category</option>
                  {data.map((list, i) => {
                    return (
                      <option value={list._id} key={list._id + i}>
                        {list.category_en}
                      </option>
                    )
                  })}
                </select>
              )}
              </div>
        </div>
        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Name(EN)</div>
          <div>
            <input
              value={nameEn}
              type='text'
              className={style.nameTexts}
              onChange={(e) => {
                setNameEn(e.target.value)
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>
        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Name(AR)</div>
          <div>
            <input
              type='text'
              value={nameAr}
              className={style.nameTexts}
              onChange={(e) => {
                setNameAr(e.target.value)
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>

        <div className={style.buttonWrapper}>
          <button className='btn btn-success' onClick={onSaveHandler}>
            Save
          </button>
          <button
            className='btn btn-danger'
            onClick={() => {
              setAddSubClassified(false)
              setSubCategories(true)
              setSubClassifiedCat({ _id: '' })
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddSubClassifiedCategory
