import React, { useState, useRef } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { BsCloudUpload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { removeLocalStorageAndNavigate } from "../../utils/helperFunctions";
import style from "./banner.module.css";
import { useGetCountriesSelectedQuery } from "../../redux/api/regionApi";
import { useGetPreSignedUrlMutation } from "../../redux/api/uploadApi";
import { useAddBannerMutation } from "../../redux/api/bannerApi";

const AddBanner = ({ setAddbanner, banner, setBanner }) => {
  const navigate = useNavigate();
  const [bannerType, setbannerType] = useState(
    banner._id ? banner.banner_type : ""
  );
  const [bannerActionUrl, setBannerActionUrl] = useState(
    banner._id ? banner.bannerActionUrl : ""
  );
  const [countryID, setCountryID] = useState(
    banner._id ? banner.country_id : "0"
  );
  const [imgsrc, setImagesrc] = useState(banner._id ? banner.banner_url : "");
  const [imgeFile, setImgeFile] = useState("");
  const [bannerPlacement, setBannerPlacement] = useState("-1");
  const [placementText, setBannerPlacementText] = useState("");
  const [loading, setLoading] = useState(false);
  const filePikerRef = useRef(null);
  const { data, error, isSuccess } = useGetCountriesSelectedQuery({});
  const [getPreSignedUrl] = useGetPreSignedUrlMutation();
  const [addBanner] = useAddBannerMutation();

  console.log(data, error);

  if (error) {
    alert(error.data.error);
  }

  const filePickerHandeler = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setImgeFile(event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImagesrc(reader.result);
      });
    } else {
      alert("no file selected");
    }
  };

  const onSaveHandler = async () => {
    if (!bannerType) {
      alert("Banner type is required");
      return;
    }
    if (!bannerActionUrl) {
      alert("Banner action url is required");
      return;
    }
    if (countryID === "0") {
      alert("country is required");
      return;
    }

    if (bannerPlacement === "-1") {
      alert("banner placment is required");
      return;
    }

    if (banner._id) {
      setLoading(true);

      if (!imgeFile && imgsrc) {
        //editClassified(imgsrc)
      }
      if (imgeFile) {
        getPresignedUrl([imgeFile.name], imgeFile.type, imgeFile);
      }
    } else {
      if (!imgeFile) {
        alert("image is required");
        return;
      }
      setLoading(true);
      getPresignedUrl([imgeFile.name], imgeFile.type, imgeFile);
    }
  };

  const addBannerHandler = async (fileurl) => {
    const res = await addBanner({
      banner_type: bannerType,
      banner_action_url: bannerActionUrl,
      banner_url: fileurl,
      country_id: countryID,
      bannerFor: placementText,
      placement: bannerPlacement,
    });

    if (res.error) {
      if (res.error.status === 401) {
        removeLocalStorageAndNavigate(navigate);
      } else {
        alert(res.error.data.error);
      }
    }
    if (res.data) {
      setImagesrc("");
      setbannerType("");
      setBannerActionUrl("");
      setImgeFile("");
      setAddbanner(false);
      setBanner({ _id: "" });
    }
    setLoading(false);
  };

  // const editClassified = async (fileurl) => {
  //   const res = await editClassifiedCategory({
  //     category_id: classified._id,
  //     category_en: nameEn,
  //     category_ar: nameAr,
  //     category_image: fileurl,
  //   })

  //   if (res.error) {
  //     if (res.error.status === 400) {
  //       alert(res.error.data.error)
  //     }
  //   }
  //   if (res.data) {
  //     setImagesrc('')
  //     setNameAr('')
  //     setNameEn('')
  //     setImgeFile('')
  //     setAddClassified(false)
  //     setClassified({ _id: '' })
  //   }
  //   setLoading(false)
  // }

  const getPresignedUrl = async (fileNames, contentType, imgeFile) => {
    const res = await getPreSignedUrl({
      fileNames: fileNames,
      contentType: contentType,
      flocation: "posts",
    });

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error);
      }
      setLoading(false);
    }
    if (res.data) {
      await uploadFile(
        res.data.preSignedUrls[0].preSignedUrlToUplaodToAWS,
        res.data.preSignedUrls[0].pathNametoStoreInDBaftersucessfullupload,
        imgeFile
      );
    }
  };

  const uploadFile = async (prsignedURL, storeTODB, imgeFile) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        let percent = Math.floor((loaded * 100) / total);
        console.log(percent);
      },
    };
    axios
      .put(prsignedURL, imgeFile, options)
      .then((res) => {
        if (banner._id) {
          // editClassified(storeTODB)
        } else {
          addBannerHandler(storeTODB);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className={style.classifiedHeaderWrapper}>Add New Banner</div>
      <div className={style.addCategoryWrapper}>
        {loading && (
          <div>
            <span className="loader-spiner"></span>
            <span className="text-primary">...adding post</span>
          </div>
        )}
        <div className={style.imageWrapper}>
          <input
            type="file"
            ref={filePikerRef}
            accept="image/*"
            className="display-n"
            onChange={filePickerHandeler}
          />
          {imgsrc && (
            <div className="text-align-center">
              <img src={imgsrc} alt="catgeory" className={style.catImage} />
              <div
                className={style.uploadImgButton}
                onClick={() => {
                  if (loading) {
                    alert("please wait its uploading");
                  } else {
                    filePikerRef.current.click();
                  }
                }}
              >
                Change Image <BsCloudUpload />
              </div>
            </div>
          )}
          {!imgsrc && (
            <div
              className={style.uploadImgButton}
              onClick={() => {
                if (loading) {
                  alert("please wait its uploading");
                } else {
                  filePikerRef.current.click();
                }
              }}
            >
              Add Image <BsCloudUpload />
            </div>
          )}
        </div>
        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Banner Type</div>
          <div>
            <input
              value={bannerType}
              type="text"
              className={style.nameTexts}
              onChange={(e) => {
                setbannerType(e.target.value);
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>
        <div className={style.nameTextsWrapper}>
          <div className={style.textLable}>Banner Action URL</div>
          <div>
            <input
              type="text"
              value={bannerActionUrl}
              className={style.nameTexts}
              onChange={(e) => {
                setBannerActionUrl(e.target.value);
              }}
              disabled={loading ? true : false}
            />
          </div>
        </div>

        <div>
          {isSuccess && data.length > 0 && (
            <select
              onChange={(e) => setCountryID(e.target.value)}
              className={`${style.selectFullWidth} mt-3 mb-3`}
              defaultValue={countryID}
            >
              <option value="0">Select Country</option>
              {data.map((list, i) => {
                return (
                  <option value={list._id} key={list._id + i}>
                    {list.country_english}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div>
          <select
            onChange={(e) => {
              const selectedText =
                e.target.options[e.target.selectedIndex].text;
              setBannerPlacementText(selectedText); // Log the selected text
              setBannerPlacement(e.target.value);
            }}
            className={`${style.selectFullWidth} mt-3 mb-3`}
            defaultValue={bannerPlacement}
          >
            <option value="-1">Select Placmennt to show ads</option>
            <option value={0}>Banner</option>
            <option value={1}>Placement1</option>
            <option value={2}>Placement2</option>
            <option value={3}>Placement3</option>
            <option value={4}>Placement4</option>
            <option value={5}>Placement5</option>
            <option value={6}>Placement6</option>
            <option value={7}>Placement7</option>
            <option value={8}>Placement8</option>
            <option value={9}>Placement9</option>
            <option value={10}>Placement10</option>
          </select>
        </div>

        <div className={style.buttonWrapper}>
          <button className="btn btn-success" onClick={onSaveHandler}>
            Save
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setAddbanner(false);
              setBanner({ _id: "" });
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
