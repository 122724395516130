import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Rootlayout from "./layouts/Rootlayout";
import UserLayout from "./layouts/UserLayout";

function App() {
  return (
    <HashRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/rm-admin" element={<Login />}></Route>
          <Route
            path="/rm-admin/dashboard"
            element={<Rootlayout component="dashbaord" />}
          ></Route>
          <Route
            path="/rm-admin/userslist"
            element={<Rootlayout component="userslist" />}
          ></Route>
          <Route
            path="/rm-admin/classifieds"
            element={<Rootlayout component="classifieds" />}
          ></Route>
          <Route
            path="/rm-admin/sub-categories"
            element={<Rootlayout component="subcategroies" />}
          ></Route>
          <Route
            path="/rm-admin/countries"
            element={<Rootlayout component="countries" />}
          ></Route>

          <Route
            path="/rm-admin/districts"
            element={<Rootlayout component="districts" />}
          ></Route>

          <Route
            path="/rm-admin/areas"
            element={<Rootlayout component="areas" />}
          ></Route>

          <Route
            path="/rm-admin/banners"
            element={<Rootlayout component="banners" />}
          ></Route>

          <Route
            path="/rm-admin/posts"
            element={<Rootlayout component="posts" />}
          ></Route>

          <Route
            path="/rm-admin/classifieds/posts"
            element={<Rootlayout component="classifiedspost" />}
          ></Route>

          <Route
            path="/rm-admin/classifieds/add-classified-post"
            element={<Rootlayout component="addclassifiedspost" />}
          ></Route>

          <Route
            path="/rm-admin/stories"
            element={<Rootlayout component="stories" />}
          ></Route>
          <Route
            path="/rm-admin/settings"
            element={<Rootlayout component="settings" />}
          ></Route>
          <Route
            path="/rm-admin/change-password"
            element={<Rootlayout component="changepassword" />}
          ></Route>
          <Route
            path="/rm-admin/reported-users"
            element={<Rootlayout component="reportedusers" />}
          ></Route>
          <Route
            path="/rm-admin/contactform"
            element={<Rootlayout component="contactform" />}
          ></Route>

          <Route
            path="/rm-admin/tickets"
            element={<Rootlayout component="tickets" />}
          ></Route>

          <Route
            path="/rm-admin/notifications"
            element={<Rootlayout component="notifications" />}
          ></Route>

          <Route
            path="/post/:id"
            element={<UserLayout component="userpost" />}
          ></Route>

          <Route
            path="/auth/email-password-change"
            element={<UserLayout component="emailpasswordchange" />}
          ></Route>
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
