import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const bannerApi = createApi({
  reducerPath: 'bannerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== 'undefined') {
        const user = JSON.parse(localStorage.getItem('RaselListing'))
        if (user?.token) headers.set('Authorization', `Bearer ${user?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['banners'],
  endpoints: (builder) => ({
    getBanners: builder.query({
      query: ({ searchText, country_id }) => ({
        url: `api/category/get-banners?textSearch=${searchText}&country_id=${country_id}`,
      }),
      providesTags: ['banners'],
    }),
    addBanner: builder.mutation({
      query(data) {
        return {
          url: `api/category/add-banner`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['banners'],
    }),

    deleteBanner: builder.mutation({
      query(data) {
        return {
          url: `api/category/delete-banner`,
          method: 'POST',
          body: data,
        }
      },
      invalidatesTags: ['banners'],
    }),
  }),
})

export const {
  useGetBannersQuery,
  useAddBannerMutation,
  useDeleteBannerMutation,
} = bannerApi
