import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const AddClassifiedPost = ({}) => {
  const Query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (!Query.get("id")) {
      alert("classified Id is missing in the url");
    }
  }, [Query]);

  return <div className="root-top-margin">add classified post</div>;
};

export default AddClassifiedPost;
