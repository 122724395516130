import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsTrash, BsPencilFill } from 'react-icons/bs'
import ListTables from '../../components/tables/ListTables'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import {
  useGetDistrictsQuery,
  useGetCountriesSelectedQuery,
} from '../../redux/api/regionApi'

import style from './region.module.css'

import AddDistricts from '../../components/countries/districts/AddDistricts'

const Countries = () => {
  const navigate = useNavigate()
  const [district, setDistrict] = useState({ _id: '' })
  const [addDistrict, setAddDistrict] = useState(false)
  const [countryID, setCountryID] = useState('KW')

  const { data, error, isSuccess } = useGetCountriesSelectedQuery({})

  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate)
    }
  }

  const dataRemap = (data) => {
    return data.map(filter)
  }

  const filter = (obj) => {
    return {
      _id: obj._id,
      id: obj._id,
      name_en: obj.district_en,
      namer_ar: obj.district_ar,
      country: obj.country_id,
    }
  }

  const editDistrict = async (district) => {
    setAddDistrict(true)
    setDistrict(district)
  }

  return (
    <div className='root-top-margin'>
      {console.log(addDistrict)}
      {!addDistrict && (
        <div>
          <div className='flex-item-space-btw'>
            {isSuccess && data.length > 0 && (
              <select
                onChange={(e) => setCountryID(e.target.value)}
                className={`mt-3 mb-3`}
                defaultValue={countryID}
              >
                <option value='0'>Select Country</option>
                {data.map((list, i) => {
                  return (
                    <option value={list._id} key={list._id + i}>
                      {list.country_english}
                    </option>
                  )
                })}
              </select>
            )}
            <div className={style.addIcon}>
              <button
                className='btn btn-success'
                onClick={() => {
                  setAddDistrict(true)
                }}
              >
                Add District
              </button>
            </div>
          </div>
          <ListTables
            search={true}
            tableTitle='Districts'
            tableHeders={['id', 'Name EN', 'Name AR', 'Country', 'Actions']}
            useGetListHook={useGetDistrictsQuery}
            paramAdditional={{ country_id: countryID }}
            dataRemap={dataRemap}
            actions={[
              {
                icon: <BsPencilFill size={20} />,
                handler: editDistrict,
              },
            ]}
            errordata={
              error &&
              (error.data.error ? error.data.error : 'some thing went wrong')
            }
          />
        </div>
      )}

      {addDistrict && (
        <AddDistricts
          setAddDistrict={setAddDistrict}
          district={district}
          setDistrict={setDistrict}
        />
      )}
    </div>
  )
}

export default Countries
