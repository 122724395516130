import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import NavbarMenu from "../components/navbar/NavbarMenu";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getLocalStorage } from "../utils/helperFunctions";
import { addUser } from "../redux/slices/UserSlice";
import Users from "../components/userlist/Users";
import Classifieds from "../pages/classifieds/Classifieds";
import Countries from "../pages/region/Countries";
import Districts from "../pages/region/Districts";
import Area from "../pages/region/Area";
import Banner from "../pages/banner/Banner";
import Posts from "../pages/posts/Posts";
import Stories from "../pages/stories/Stories";
import ClassifiedPosts from "../pages/classifieds/ClassifiedPosts";
import Dashboard from "../pages/DashBoard/Dashboard";
import Settings from "../pages/settings/Settings";
import ChangePassword from "../pages/settings/ChangePassword";
import ReportedUsers from "../components/userlist/ReportedUsers";
import ContactFrom from "../pages/contactform/ContactFrom";
import Tickets from "../pages/tickets/Tickets";
import Notification from "../pages/notifications/Notification";
import Logout from "./Logout";
import AddClassified from "../components/classifieds/AddClassified";
import AddClassifiedPost from "../pages/addclassifiedspost/AddClassifiedPost";

const Rootlayout = ({ component }) => {
  const dispatch = useDispatch({});
  const [classifiedPost, setClassifiedPost] = useState("");
  const [navOpen, setNavOpen] = useState(false);
  useEffect(() => {
    if (typeof Storage !== "undefined") {
      const user = getLocalStorage();
      if (user)
        if (user.token) {
          dispatch(addUser(user));
        }
    } else {
      alert("Update browser");
    }
  }, [dispatch]);
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-none">
          <NavbarMenu
            setClassifiedPost={setClassifiedPost}
            setNavOpen={setNavOpen}
            navOpen={navOpen}
          />
          <Logout setNavOpen={setNavOpen} navOpen={navOpen} />
        </Col>

        {component === "dashbaord" && (
          <Col>
            <Dashboard />
          </Col>
        )}
        {component === "userslist" && (
          <Col>
            <Users />
          </Col>
        )}
        {component === "classifieds" && (
          <Col>
            <Classifieds />
          </Col>
        )}
        {component === "countries" && (
          <Col>
            <Countries />
          </Col>
        )}

        {component === "districts" && (
          <Col>
            <Districts />
          </Col>
        )}

        {component === "areas" && (
          <Col>
            <Area />
          </Col>
        )}

        {component === "banners" && (
          <Col>
            <Banner />
          </Col>
        )}

        {component === "posts" && (
          <Col>
            <Posts />
          </Col>
        )}

        {component === "stories" && (
          <Col>
            <Stories />
          </Col>
        )}

        {component === "settings" && (
          <Col>
            <Settings />
          </Col>
        )}

        {component === "changepassword" && (
          <Col>
            <ChangePassword />
          </Col>
        )}

        {component === "reportedusers" && (
          <Col>
            <ReportedUsers />
          </Col>
        )}

        {component === "classifiedspost" && (
          <Col>
            <ClassifiedPosts
              classifiedPost={classifiedPost}
              setClassifiedPost={setClassifiedPost}
            />
          </Col>
        )}

        {component === "addclassifiedspost" && (
          <Col>
            <AddClassifiedPost />
          </Col>
        )}

        {component === "contactform" && (
          <Col>
            <ContactFrom />
          </Col>
        )}

        {component === "tickets" && (
          <Col>
            <Tickets />
          </Col>
        )}

        {component === "notifications" && (
          <Col>
            <Notification />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Rootlayout;
