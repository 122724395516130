import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BiSolidLike, BiSolidCommentDots } from 'react-icons/bi'
import { IoShareSocialOutline } from 'react-icons/io5'
import style from './post.module.css'
import { removeLocalStorageAndNavigate } from '../../utils/helperFunctions'
import {
  useGetPostQuery,
  useActiveDisActivePostMutation,
} from '../../redux/api/postApi'
import image from '../../images/user2.jpg'
import LatestComments from './LatestComments'
import UserReportedOnAPost from './UserReportedOnAPost'
const Post = ({ ID, setViewPost }) => {
  const [show, setShow] = useState(true)
  const navigate = useNavigate()
  const { data, error, isLoading, isSuccess } = useGetPostQuery({
    post_id: ID,
  })

  useEffect(() => {
    if (isSuccess) setShow(data.active)
  }, [isSuccess, data])

  const [activeDisActivePost] = useActiveDisActivePostMutation()

  console.log('data dsdas')
  if (error) {
    if (error.status === 401) {
      removeLocalStorageAndNavigate(navigate)
    } else {
      alert(error.data.error)
    }
  }

  const handleActivePost = async (id, active) => {
    const res = await activeDisActivePost({
      post_id: id,
      active: active,
    })

    if (res.error) {
      if (res.error.status === 400) {
        alert(res.error.data.error)
      }
    }
    if (res.data) {
      setShow(active)
    }
  }

  return (
    <div>
      <div className={style.boxWrapper}>
        {isLoading && (
          <div className='flex-item-center'>
            <span className='loader-spiner'></span>
          </div>
        )}

        <button
          className='btn btn-danger'
          onClick={() => {
            setViewPost('')
          }}
        >
          Back
        </button>
        {isSuccess && (
          <div>
            <div className='flex-item-center'>
              <img
                src={data.user_id.photo}
                alt={data.user_id.name}
                onError={(e) => {
                  e.target.onError = null
                  e.target.src = image
                }}
                className={style.imgRound}
              />
            </div>
            <h1>{data.title}</h1>

            <div className='flex-item-space-btw'>
              <div>
                <div className='pt-2 pb-2'>
                  <span>Posted User:</span>
                  <span className='ml-2'>{data.user_id.name}</span>
                </div>

                <div className='pt-2 pb-2'>
                  <span>Created:</span>
                  <span>
                    {new Date(data.createdAt).toLocaleDateString('de-DE')},
                  </span>
                  <span>
                    {new Date(data.createdAt).toLocaleTimeString('en-US')}
                  </span>
                </div>
                <div className='pt-2 pb-2'>
                  <span>Status:</span>
                  <span className='ml-2'>
                    {data.active ? 'Active' : 'Not Active'}
                  </span>
                </div>
                <div className='pt-2 pb-2'>
                  <span>
                    <BiSolidLike />
                    <span className='ml-2'>Total Likes :</span>
                  </span>
                  <span className='ml-2'>{data.totalLikes}</span>
                </div>
                <div className='pt-2 pb-2'>
                  <span>
                    <IoShareSocialOutline />
                    <span className='ml-2'>Total Shares :</span>
                  </span>
                  <span className='ml-2'>{data.totalShares}</span>
                </div>
                <div className='pt-2 pb-2'>
                  <span>
                    <BiSolidCommentDots />
                    <span className='ml-2'>Total Comments :</span>
                  </span>
                  <span className='ml-2'>{data.totalComments}</span>
                </div>
                <div className='pt-2 pb-2'>
                  <span>
                    <BiSolidCommentDots />
                    <span className='ml-2'>Show posts :</span>
                  </span>
                  <input
                    type='checkbox'
                    onChange={(e) =>
                      handleActivePost(data._id, e.target.checked)
                    }
                    className='ml-2'
                    checked={show}
                  />
                </div>
              </div>

              <LatestComments post_id={ID} />
            </div>
          </div>
        )}
      </div>
      <UserReportedOnAPost post_id={ID} />
    </div>
  )
}

export default Post
