import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const regionApi = createApi({
  reducerPath: "regionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_backendurl,
    prepareHeaders: (headers) => {
      if (typeof Storage !== "undefined") {
        const user = JSON.parse(localStorage.getItem("RaselListing"));
        if (user?.token) headers.set("Authorization", `Bearer ${user?.token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["countries", "districts"],
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: ({ searchText }) => ({
        url: `api/getcountries-admin?textSearch=${searchText}`,
      }),
      providesTags: ["countries"],
    }),
    getCountriesSelected: builder.query({
      query: ({}) => ({
        url: `api/countries`,
      }),
      providesTags: ["countries"],
    }),
    updateSelectCountry: builder.mutation({
      query(data) {
        return {
          url: `api/update-countries-admin`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["countries"],
    }),
    getDistricts: builder.query({
      query: ({ searchText, country_id }) => ({
        url: `api/get-districts?country_id=${country_id}&textSearch=${searchText}`,
      }),
      providesTags: ["districts"],
    }),
    addDistrict: builder.mutation({
      query(data) {
        return {
          url: `api/add-district`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["districts"],
    }),

    editDistrict: builder.mutation({
      query(data) {
        return {
          url: `api/edit-district`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["districts"],
    }),

    addArea: builder.mutation({
      query(data) {
        return {
          url: `api/add-area`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["areas"],
    }),

    editArea: builder.mutation({
      query(data) {
        return {
          url: `api/edit-area`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["areas"],
    }),

    getArea: builder.query({
      query: ({ searchText, district_id, country_id }) => {
        if (district_id != 0) {
          return {
            url: `api/get-area-admin?country_id=${country_id}&district_id=${district_id}&textSearch=${searchText}`,
          };
        } else {
          return {
            url: `api/get-area-admin?country_id=${country_id}&textSearch=${searchText}`,
          };
        }
      },
      providesTags: ["areas"],
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useGetCountriesSelectedQuery,
  useUpdateSelectCountryMutation,
  useGetDistrictsQuery,
  useAddDistrictMutation,
  useEditDistrictMutation,
  useGetAreaQuery,
  useAddAreaMutation,
  useEditAreaMutation,
} = regionApi;
